





















































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        cost: "",
        discountCode: null,
        companion: null,
        registrationCode: null,
      }),
    },
    currency: {
      type: String,
      required: false,
      default: "PLN",
    },
  },

  setup(props, { root }) {
    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
      empty: false,
      table: false,
      loaded: false,
      items: [],
      registrationDiscount: {},
      discountCode: "",
    });

    const fetchFees = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/registration-fee/group`)
        .then(({ data }) => {
          state.empty = false;
          state.items = data.map((registrationFeeGroup: any) => ({
            ...registrationFeeGroup,
          }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchFees);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("panel.event.discount.discountCodeNotFound")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const checkDiscountCode = (code: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`discount-code/${code}/registrationFee/${props.value.cost}`)
        .then(({ data }) => {
          state.registrationDiscount = data;
          props.value.discountCode = code;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("panel.event.discount.codeValid"),
          });
        })
        .catch((error) => {
          state.error = error.response?.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        });
    };

    watch(
      () => props.value.cost,
      () => {
        props.value.companion = null;
        props.value.registrationCode = null;
      }
    );

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    return { state, checkDiscountCode, rules };
  },
});
